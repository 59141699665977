.ResetPassword-container {
	min-width: 25rem;
}
.ResetPassword-form {
	width: 100%;
}
.ResetPassword-error {
	color: red;
	margin: 1rem;
	width:100%;
}

.ResetPassword-sent {
	width: 100%;
	margin: 1rem;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 1rem;
}