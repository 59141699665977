.Home-client-container {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border: 1px solid #c4c5c5;
	border-radius: 3px;
	padding: 1rem;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 1rem;
}

.Home-client {
	margin-bottom: 0.5rem;
	cursor: pointer;
	width: 14.18rem;
}

.Home-client-header {
	margin-block-start: 0;
	margin-block-end: 0;
}

.Home-client-actions {
	display: flex;
	justify-content: flex-end;
}

.Home-error {
	color: red;
}

.Home-dialog-form {
	margin: 0.5rem 0 0;
}

.Home-dialog-error {
	color: red;
}

.Home-dialog-field {
	margin-bottom: 1rem;
}