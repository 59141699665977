.Login-container {
	min-width: 25rem;
}
.Login-form {
	width: 100%;
}
.Login-error {
	color: red;
	margin: 1rem;
	width:100%;
}
